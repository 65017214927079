import React from "react";
import '../App.css';
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";

const HeaderComponent = (props) => {

    return (
        <section>
            <MediaQuery minWidth={561} maxWidth={700}>
            <div style={{textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', border: '3px solid black', aspectRatio: '1.18', width: '90%', paddingLeft: '10px', paddingRight: '10px'}}>
                <h2 style={{fontSize: '22px', marginTop: '8px'}}>{props.title}</h2>
                <p style={{marginTop: '-10px', fontSize: '17px'}}>{props.description}</p>
            </div>
            </MediaQuery>
            <MediaQuery minWidth={701}>
            <div style={{border: '3px solid black', height: '200px',minWidth: '320px', maxWidth: '360px', padding: '0px 0px 4px 4px'}}>
                <h2 style={{fontSize: '22px', marginTop: '8px'}}>{props.title}</h2>
                <p style={{marginTop: '-10px', fontSize: '17px'}}>{props.description}</p>
            </div>
            </MediaQuery>
            <MediaQuery maxWidth={560}>
            <div style={{border: '3px solid black', aspectRatio: '1.18', padding: '0px 0px 0px 0px', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <div style={{textAlign: 'center', width: '90%'}}>
                    <h2 style={{fontSize: '30px', marginTop: '17px', fontWeight: 'bolder'}}>{props.title}</h2>
                    <p style={{marginTop: '-10px', fontSize: '17px'}}>{props.description}</p>
                </div>
            </div>
            </MediaQuery>
        </section>
    );

};

export default HeaderComponent;