import React from "react";
import '../App.css';
import { Link } from "react-router-dom";
import HeaderComponent from "./HeaderComponent";
import WorkComponent from "./WorkComponent";
import MediaQuery from "react-responsive";
import Marquee from "react-fast-marquee";

const HomePage = () => {

    return (
        <section>
            <MediaQuery minWidth={901}>
            <div id="contentDiv">
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '92vw', textAlign: 'center', marginTop: '-30px', height: '100%'}}>
                    <div style={{display: 'flex', width: '100%'}}>
                    {/* <h1 style={{fontSize: '14px'}}>--- LINKS TO SOCIALS ---</h1> */}
                        <div style={{display: 'flex', flexDirection: 'row', gap: '2px', marginBottom: '20px', marginTop: '50px'}}>
                            {/* <h1 id="hoverHeader">ELIJAH ASSETS</h1>
                            <h1 id="hoverHeader">X</h1>
                            <h1 id="hoverHeader">INSTAGRAM</h1>
                            <h1 id="hoverHeader">YOUTUBE</h1>
                            <h1 id="hoverHeader">EMAIL</h1> */}
                            <a href="https://x.com/Eli1ah">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/X.png')}></img>
                            </a>
                            <a href="https://instagram.com/eli1ah">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/INSTA.png')}></img>
                            </a>
                            <a href="https://www.youtube.com/channel/UChNAhXKwcjedUY2KNtIQSXg/videos">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/YOUTUBE.png')}></img>
                            </a>
                            {/* <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/EMAIL.png')}></img> */}
                            <a href="https://maketheting.bandcamp.com/album/make-the-ting">
                                <img style={{width: '40px', aspectRatio: '1', marginTop: '1px'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/BANDCAMP.png')}></img>
                            </a>
                            <a href="https://open.spotify.com/artist/09ub5MJMcOlgG7WFbszSuf?si=TehfuOpGTx6GVXM6UvPaYw">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/SPOTIFY.png')}></img>
                            </a>
                        </div>
                    </div>
                    {/* INTRODUCTION ITEMS */}
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'right', justifyContent: 'space-between'}}>
                        <div style={{width: '65%', maxWidth: '600px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                            <h2 id="subTitle" style={{fontSize: '40px', width: '100%', marginLeft: '0%', marginTop: '0px', textAlign: 'left', lineHeight: '40px', fontWeight: '500'}}>I’m <span><img style={{width: '160px', mixBlendMode: 'multiply'}} src={require('../Assets/Elijah_Logo_24.png')}></img></span>, a creative consultant, lecturer and artist with 15 years experience in the Independent Music and Arts sector in London.</h2>
                            <div style={{marginTop: '-20px'}}>
                                <p className="web-p" style={{width: '75%', marginTop: '0px', textAlign: 'left'}}>Get in touch if you want to work on a new idea, want me to lecture at your company, institution or festival or would like to commission me as an artist or book me as a DJ. I occasionally do campaign work with brands I share values with.</p>
                            </div>
                        </div>
                        {/* <div style={{width: '35%', border: '1px solid red', textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'end'}}> */}
                        <div style={{textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                            <h2 style={{fontSize: '22px', fontWeight: '600'}}>My work for companies and artists has covered:</h2>
                            {/* <div style={{display: 'flex', flexDirection: 'column', gap: '0px'}}> */}
                            {/* <ul> */}
                                <p id="paragraph" style={{marginTop: '-14px', fontSize: '14px', fontWeight: 'normal', textAlign: 'right'}}>Brand Strategy & Product Development</p>
                                <p id="paragraph" style={{marginTop: '-14px', fontSize: '14px', fontWeight: 'normal', textAlign: 'right'}}>Artist Management for Flava D, Swindle, DJ Q, TQD</p>
                                <p id="paragraph" style={{marginTop: '-14px', fontSize: '14px', fontWeight: 'normal', textAlign: 'right'}}>Launching a fund for 18-25 year old emerging musicians</p>
                            {/* </ul> */}
                            {/* </div> */}
                        </div>
                        {/* </div> */}
                    </div>
                    <hr style={{border: '1px solid black', width: '100vw', marginLeft: '-4vw', marginBottom: '4px'}}></hr>
                    {/* <div className="ticker-container">
                        <div className="ticker-text" > */}
                        <div style={{width: '100vw'}}>
                            <Marquee>
                                <p style={{marginTop: '0px', marginBottom: '0px', fontWeight: '350'}}>Are the ideas still true at scale? You can't copyright vibe. Imposter syndrome is a feature not a bug. Close the app. Make the ting. The process is the art. Document your ting. It's not an ecosystem if everyone is doing the same thing. Social media is a canvas. Friends buy tickets. Create your own lecture. Make zero your favourite number. Practise makes more tings. It may all make sense in the end. Your plan assumes everything stays the same. More time you don't need more time. Time is the creative director. Nobody knows what's going to happen next. </p>
                            </Marquee>
                        </div>
                        {/* </div>
                    </div> */}
                    {/* <p style={{whiteSpace: 'nowrap', fontWeight: '500', marginTop: '0px', marginBottom: '0px'}}>Are the ideas still true at scale? You can't copyright vibe. Imposter syndrome is a feature not a bug. Close the app. Make the ting. The process is the art. Document your ting. It's not an ecosystem if everyone is doing the same thing. Social media is a canvas.</p> */}
                    {/* <p style={{whiteSpace: 'nowrap', fontWeight: '500'}}>Social media is a canvas. Use it in the most artistic way possible. Friends buy tickets.</p> */}
                    <hr style={{border: '1px solid black', width: '100vw', marginLeft: '-4vw', marginTop: '4px'}}></hr>
                    {/* HEADER ITEMS */}
                    {/* <p style={{textAlign: 'left', width: '100%', marginBottom: '-14px'}}>Scroll right to view my projects</p> */}
                    <div className="work-section" style={{textAlign: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row-reverse', overflowX: 'auto', msOverflowStyle: 'none', scrollbarWidth: 'none', columnGap: '20px', marginTop: '-10px', width: '100%', marginBottom: '40px', height: '100%'}}>
                        {/* <HeaderComponent title="LECTURES" description="I’m an ambassador for V&A East, a community fellow at SOAS University, and have lectured globally on new ways of thinking about building creative careers, using social media as a canvas, and rethinking arts education."></HeaderComponent>
                        <HeaderComponent title="YELLOW SQUARES" description="All of this work comes together publicly in my project, the ‘Yellow Squares’ which started in 2021, sharing ideas on building creative ecosystems. It has developed into my feedback loop of work and collaboration that have had impact globally."></HeaderComponent>
                        <HeaderComponent title="OPEN SOURCE APPROACH" description="As an artist I explore an Open Source approach to creating and sharing through Music, DJing and Visual Installations."></HeaderComponent>
                        <HeaderComponent title="MUSICAL JOURNEY" description="My journey has included running a Grime record label, being a pirate radio host on Rinse FM and writing about Electronic Music Culture."></HeaderComponent> */}
                        {/* <h style={{fontSize: ''}}>ARCHIVE WORK</h>
                        <span style={{width: '100%', border: '1px solid black'}}></span> */}
                        
                        <div style={{display: 'flex', marginTop: '0px', flexDirection: 'column', width: '46%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                            {/* <HeaderComponent title="LECTURES" description="I’m an ambassador for V&A East, a community fellow at SOAS University, and have lectured globally on new ways of thinking about building creative careers, using social media as a canvas, and rethinking arts education."></HeaderComponent> */}
                            <div style={{width: '100%', display: 'flex', textAlign: 'left', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '-24px'}}>
                                <h2 style={{width: '100%', fontSize: '28px', fontWeight: '600'}}>Lectures</h2>
                                <p className="web-p" style={{marginTop: '-10px', width: '100%'}}>I’m an ambassador for V&A East, a community fellow at SOAS University, and have lectured globally on new ways of thinking about building creative careers, using social media as a canvas, and rethinking arts education.</p>
                            </div>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'left', gap: '10px'}}>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'left'}}>
                                    <a href="https://www.youtube.com/watch?v=aqgdArod1jU">
                                        <img id="workThumbnail" style={{width: '160px', aspectRatio: '1', border: '2.5px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px'}}>Break Free</p>
                                        <p className="web-p" style={{marginLeft: '20px', textAlign: 'left'}}>
                                        An evolving talk and Q&A on creating with what you have, reflective of the core topics in the Yellow Square project on Instagram. Have done this in London, Auckland, Ho Chi Minh City, São Paulo, Tokyo, Istanbul, Ibiza, Barcelona, Oslo, Brussels, Berlin and across India.
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'left'}}>
                                <a href="https://youtu.be/balv1LICF_s?si=tNbVtdwDbC6EhCWv">
                                    <img id="workThumbnail" style={{width: '160px', aspectRatio: '1', border: '2.5px solid black'}} src={require('../Assets/CLOSETHEAPP.jpg')}></img>
                                </a>
                                <div>
                                    <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px'}}>Close The App</p>
                                    <p className="web-p" style={{marginLeft: '20px', textAlign: 'left'}}>
                                        An alternative take on the wide possibilities of the future of music and DJ culture. New for 2024.
                                    </p>
                                </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'left'}}>
                                <a href="https://www.youtube.com/watch?v=jFGJzoexmME">
                                    <img id="workThumbnail" style={{width: '160px', aspectRatio: '1', border: '2.5px solid black', mixBlendMode: 'darken'}} src={require('../Assets/EMMAWARREN.jpg')}></img>
                                </a>
                                <div>
                                    <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px'}}>Back to Back with Emma Warren</p>
                                    <p className="web-p" style={{marginLeft: '20px', textAlign: 'left'}}>
                                        A collaboration lecture with a fellow artist with me interrogating their work using my Yellow Square design language. These are one off specials. Watch Back To Back with Emma Warren at SOAS.
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div style={{border: '1px solid black', marginTop: '0px'}}></div>
                        <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '46%', paddingLeft: '0', paddingRight: '0', marginTop: '0px', marginBottom: '30px', alignItems: 'center', justifyContent: 'center'}}>
                            
                            {/* <HeaderComponent title="YELLOW SQUARES" description="All of this work comes together publicly in my project the ‘Yellow Squares’ which started in 2021 on Instagram, sharing ideas on building creative ecosystems. It has developed into a deep feedback loop of work and collaborations that has included an album, a visual installation, public billboards and countless remixes of my work. I am available for new commissions, and collaborations that expand this project."></HeaderComponent> */}
                            <div style={{width: '100%', display: 'flex', textAlign: 'left', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '-24px'}}>
                                <h2 style={{width: '100%', fontSize: '28px', fontWeight: '600'}}>Yellow Squares</h2>
                                <p className="web-p" style={{marginTop: '-10px', width: '100%'}}>All of this work comes together publicly in my project the ‘Yellow Squares’ which started in 2021 on Instagram, sharing ideas on building creative ecosystems. It has developed into a deep feedback loop of work and collaborations that has included an album, a visual installation, public billboards and countless remixes of my work. I am available for new commissions, and collaborations that expand this project.</p>
                            </div>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '20px'}}>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    {/* <a href="https://www.youtube.com/watch?v=jFGJzoexmME"> */}
                                        <img id="workThumbnail" style={{width: '160px', aspectRatio: '1', border: '2.5px solid black', mixBlendMode: 'normal', objectFit: 'cover'}} src={require('../Assets/360_1.jpeg')}></img>
                                    {/* </a> */}
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px'}}>360 Lecture</p>
                                        <p className="web-p" style={{marginLeft: '20px', textAlign: 'left'}}>
                                        I collaborated with Lighthouse and The Old Market in Brighton to make an immersive version of my lecture, and album with my long time collaborator Royal-T working with me on the visuals. This is a work in progress we hope to bring to more cities in the future.
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://maketheting.bandcamp.com/album/make-the-ting">
                                        <img id="workThumbnail" style={{width: '160px', aspectRatio: '1', border: '2.5px solid black', mixBlendMode: 'darken'}} src={require('../Assets/MAKETHETING.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px'}}>Make The Ting</p>
                                        <p className="web-p" style={{marginLeft: '20px', textAlign: 'left'}}>
                                        I collaborated with Grime MC Jammz to make an album based on my writing in one week, which we released the acapellas for so other artists could remix. We pressed a vinyl edition of the album with a blank cover, and I drew custom artwork for every sale, making hundreds of different covers. Collaborations with Jammz will continue to stretch these ideas.
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://www.buildhollywood.co.uk/features/elijahs-yellow-squares-began-life-on-instagram-now-were-collaborating-with-him-as-part-of-your-space-or-mine-with-a-series-of-new-billboards/">
                                        <img id="workThumbnail" style={{width: '160px', aspectRatio: '1', border: '2.5px solid black', mixBlendMode: 'normal', objectFit: 'cover'}} src={require('../Assets/BILLBOARDMTT.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px'}}>Billboards</p>
                                        <p className="web-p" style={{marginLeft: '20px', textAlign: 'left'}}>
                                        I collaborated with <a className="inline-link" href="https://www.buildhollywood.co.uk/features/elijahs-yellow-squares-began-life-on-instagram-now-were-collaborating-with-him-as-part-of-your-space-or-mine-with-a-series-of-new-billboards/">Build Hollywood</a> to bring my writing into the real world, starting in my local community, then across London in 2023. I'm interested in working on projects that take very online ideas into the physical world.
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://drive.google.com/drive/folders/1QkxYOZM_fL38yko2r6rs7tuiF9hBS9ua?usp=sharing">
                                        <img id="workThumbnail" style={{width: '160px', aspectRatio: '1', border: '2.5px solid black'}} src={require('../Assets/ELIJAH ▢ - Specialist.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px'}}>Yellow Squares Archive</p>
                                        <p className="web-p" style={{marginLeft: '20px', textAlign: 'left'}}>
                                        You can <a className="inline-link" href="https://drive.google.com/drive/folders/1QkxYOZM_fL38yko2r6rs7tuiF9hBS9ua?usp=sharing">download the squares directly here</a>, and create your own lecture, art or ideas based on these. Just send me what you made. They are open source.
                                        </p>
                                    </div>
                                </div>
                                {/* <img style={{width: '40%', border: '3px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img> */}
                            </div>
                        </div>
                    </div>
                    <hr style={{border: '1px solid black', width: '100vw', marginLeft: '-4vw', marginTop: '-40px', marginBottom: '30px'}}></hr>
                    <div style={{width: '100%', marginBottom: '20px', textAlign: 'left', display: 'none', flexDirection: 'column'}}>
                        <div style={{marginBottom: '20px', width: '100%'}}>
                            <h id="subTitle" style={{fontSize: '40px', width: '100%', marginLeft: '0%', marginTop: '0px', textAlign: 'center', lineHeight: '40px'}}>360 Immersive Lecture @ Market House, Brighton</h>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', gap: '20px'}}>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_1.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_2.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_3.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                        </div>
                        <p style={{marginTop: '20px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </div>
            </div>
            </MediaQuery>
            <MediaQuery minWidth={561} maxWidth={900}>
            <div id="contentDiv">
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '92vw', textAlign: 'center', marginTop: '-30px'}}>
                    {/* SOCIAL MEDIA ITEMS */}
                    <div style={{display: 'flex', width: '100%', justifyContent: 'left'}}>
                        {/* <h1 style={{fontSize: '14px'}}>--- LINKS TO SOCIALS ---</h1> */}
                        {/* <div style={{display: 'flex',justifyContent: 'space-between', alignContent: 'space-between', width: '100%', flexDirection: 'row', marginBottom: '20px', marginTop: '50px'}}> */}
                        {/* <div style={{display: 'flex',justifyContent: 'center', maxWidth: '700' , textAlign: 'center', width: '100%', border: '1px solid red', flexDirection: 'row', marginBottom: '20px', marginTop: '50px'}}> */}
                        <div style={{display: 'flex', width: '100%', flexDirection: 'row', gap: '2px', marginBottom: '20px', marginTop: '50px'}}>
                            {/* <h1 style={{fontSize: '14px'}} id="hoverHeader">ELIJAH ASSETS</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">X</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">INSTAGRAM</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">YOUTUBE</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">EMAIL</h1> */}
                            <a href="https://x.com/Eli1ah">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/X.png')}></img>
                            </a>
                            <a href="https://instagram.com/eli1ah">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/INSTA.png')}></img>
                            </a>
                            <a href="https://www.youtube.com/channel/UChNAhXKwcjedUY2KNtIQSXg/videos">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/YOUTUBE.png')}></img>
                            </a>
                            {/* <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/EMAIL.png')}></img> */}
                            <a href="https://maketheting.bandcamp.com/album/make-the-ting">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/BANDCAMP.png')}></img>
                            </a>
                            <a href="https://open.spotify.com/artist/09ub5MJMcOlgG7WFbszSuf?si=TehfuOpGTx6GVXM6UvPaYw">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/SPOTIFY.png')}></img>
                            </a>
                        </div>
                    </div>
                    {/* INTRODUCTION ITEMS */}
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                            <h2 id="subTitle" style={{fontSize: '40px', width: '100%', marginLeft: '0%', marginTop: '0px', textAlign: 'left', lineHeight: '40px', fontWeight: '500'}}>I’m <span><img style={{width: '160px', mixBlendMode: 'multiply'}} src={require('../Assets/Elijah_Logo_24.png')}></img></span>, a creative consultant, lecturer and artist with 15 years experience in the Independent Music and Arts sector in London.</h2>
                            <div style={{marginTop: '-20px', display: 'flex', justifyContent: 'center'}}>
                                <p style={{width: '100%', fontSize: '15px', marginTop: '0px', textAlign: 'left'}}>Get in touch if you want to work on a new idea, want me to lecture at your company, institution or festival or would like to commission me as an artist or book me as a DJ. I occasionally do campaign work with brands I share values with.</p>
                            </div>
                        </div>
                        {/* <hr style={{width: '100vw', border: '0.7px solid', marginLeft: '-4vw'}}></hr> */}
                        <div style={{width: '100%', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '-10px'}}>
                            <h2 style={{fontSize: '21px', fontWeight: '450'}}>My work for companies and artists has covered:</h2>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '0px'}}>
                                <p id="paragraph" style={{marginTop: '-8px', fontSize: '15px' , fontWeight: 'normal', textAlign: 'left'}}><span style={{fontWeight: '700'}}>+</span> Artist Management for <a className="inline-link" href="https://youtu.be/QA2iOLDB7l8?si=_Bfls4jxjYgKZ4gQ">Flava D</a>, <a className="inline-link" href="https://youtu.be/GWVM8KHFQFI?si=UHrY4sg2aMXuR6yn">Swindle</a>, <a className="inline-link" href="https://youtu.be/IHWSd3rcH0c?si=pkrF3JJYlbHxZqGR">DJ Q</a>, <a className="inline-link" href="https://youtu.be/IHWSd3rcH0c?si=pkrF3JJYlbHxZqGR">TQD</a></p>
                                <p id="paragraph" style={{marginTop: '-10px', fontSize: '15px' , fontWeight: 'normal', textAlign: 'left'}}><span style={{fontWeight: '700'}}>+</span> Co Founding <a className="inline-link" href="https://youtu.be/f0gawKpIFgU?si=vuT8TJgT9T7lbS8S">Butterz, a record label & events brand [2010-2021]</a></p>
                                <p id="paragraph" style={{marginTop: '-10px', fontSize: '15px', fontWeight: 'normal', textAlign: 'left'}}><span style={{fontWeight: '700'}}>+</span> Brand Strategy, Product Development and Creative Direction</p>
                                <p id="paragraph" style={{marginTop: '-10px', fontSize: '15px', fontWeight: 'normal', textAlign: 'left'}}><span style={{fontWeight: '700'}}>+</span> Launching a <a className="inline-link" href="https://newsroom.tiktok.com/en-gb/tiktok-partners-youth-music-nextgen-fund">fund for 18-25 year old emerging musicians</a></p>
                            </div>
                        </div>
                    </div>
                    <hr style={{border: '1px solid black', width: '100vw', marginLeft: '-4vw', marginBottom: '4px'}}></hr>
                        <div style={{width: '100vw'}}>
                            <Marquee>
                                <p style={{marginTop: '0px', marginBottom: '0px', fontWeight: '350'}}>Are the ideas still true at scale? You can't copyright vibe. Imposter syndrome is a feature not a bug. Close the app. Make the ting. The process is the art. Document your ting. It's not an ecosystem if everyone is doing the same thing. Social media is a canvas. Friends buy tickets. Create your own lecture. Make zero your favourite number. Practise makes more tings. It may all make sense in the end. Your plan assumes everything stays the same. More time you don't need more time. Time is the creative director. Nobody knows what's going to happen next. </p>
                            </Marquee>
                        </div>
                    <hr style={{border: '1px solid black', width: '100vw', marginLeft: '-4vw', marginTop: '4px'}}></hr>
                    {/* HEADER ITEMS */}
                    {/* <p style={{textAlign: 'left', width: '100%', marginBottom: '-14px'}}>Scroll right to view my projects</p> */}
                    <div className="work-section" style={{textAlign: 'center', display: 'flex', overflowX: 'auto', msOverflowStyle: 'none', scrollbarWidth: 'none', flexDirection: 'column', columnGap: '20px', marginTop: '10px', width: '100%', marginBottom: '40px'}}>
                        {/* <HeaderComponent title="LECTURES" description="I’m an ambassador for V&A East, a community fellow at SOAS University, and have lectured globally on new ways of thinking about building creative careers, using social media as a canvas, and rethinking arts education."></HeaderComponent>
                        <HeaderComponent title="YELLOW SQUARES" description="All of this work comes together publicly in my project, the ‘Yellow Squares’ which started in 2021, sharing ideas on building creative ecosystems. It has developed into my feedback loop of work and collaboration that have had impact globally."></HeaderComponent>
                        <HeaderComponent title="OPEN SOURCE APPROACH" description="As an artist I explore an Open Source approach to creating and sharing through Music, DJing and Visual Installations."></HeaderComponent>
                        <HeaderComponent title="MUSICAL JOURNEY" description="My journey has included running a Grime record label, being a pirate radio host on Rinse FM and writing about Electronic Music Culture."></HeaderComponent> */}
                        {/* <h style={{fontSize: ''}}>ARCHIVE WORK</h>
                        <span style={{width: '100%', border: '1px solid black'}}></span> */}
                        <div style={{display: 'flex', marginTop: '-20px', flexDirection: 'column', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                            {/* <HeaderComponent title="LECTURES" description="I’m an ambassador for V&A East, a community fellow at SOAS University, and have lectured globally on new ways of thinking about building creative careers, using social media as a canvas, and rethinking arts education."></HeaderComponent> */}
                            <div style={{width: '100%', display: 'flex', textAlign: 'left', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '-24px'}}>
                                <h2 style={{width: '100%', fontSize: '30px', fontWeight: '600'}}>Lectures</h2>
                                <p style={{marginTop: '-10px', width: '100%'}}>I’m an ambassador for V&A East, a community fellow at SOAS University, and have lectured globally on new ways of thinking about building creative careers, using social media as a canvas, and rethinking arts education.</p>
                            </div>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://www.youtube.com/watch?v=aqgdArod1jU">
                                        <img id="workThumbnail" style={{width: '200px', aspectRatio: '1', border: '2px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Break Free</p>
                                        <p className="work-description">
                                        An evolving talk and Q&A on creating with what you have, reflective of the core topics in the Yellow Square project on Instagram. Have done this in London, Auckland, Ho Chi Minh City, São Paulo, Tokyo, Istanbul, Ibiza, Barcelona, Oslo, Brussels, Berlin and across India.
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                <a href="https://youtu.be/balv1LICF_s?si=tNbVtdwDbC6EhCWv">
                                    <img id="workThumbnail" style={{width: '200px', aspectRatio: '1', border: '2px solid black'}} src={require('../Assets/CLOSETHEAPP.jpg')}></img>
                                </a>
                                <div>
                                    <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Close The App</p>
                                    <p className="work-description">
                                        An alternative take on the wide possibilities of the future of music and DJ culture. New for 2024.
                                    </p>
                                </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                <a href="https://www.youtube.com/watch?v=jFGJzoexmME">
                                    <img id="workThumbnail" style={{width: '200px', aspectRatio: '1', border: '2px solid black', mixBlendMode: 'darken'}} src={require('../Assets/EMMAWARREN.jpg')}></img>
                                </a>
                                <div>
                                    <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Back to Back with Emma Warren</p>
                                    <p className="work-description">
                                        A collaboration lecture with a fellow artist with me interrogating their work using my Yellow Square design language. These are one off specials. Watch Back To Back with Emma Warren at SOAS.
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', height: '100%', marginTop: '10px', alignItems: 'center', justifyContent: 'center'}}>
                            {/* <HeaderComponent title="YELLOW SQUARES" description="All of this work comes together publicly in my project the ‘Yellow Squares’ which started in 2021 on Instagram, sharing ideas on building creative ecosystems. It has developed into a deep feedback loop of work and collaborations that has included an album, a visual installation, public billboards and countless remixes of my work. I am available for new commissions, and collaborations that expand this project."></HeaderComponent> */}
                            <div style={{width: '100%', display: 'flex', textAlign: 'left', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '-24px'}}>
                                <h2 style={{width: '100%', fontSize: '30px', fontWeight: '600'}}>Yellow Squares</h2>
                                <p style={{marginTop: '-10px', width: '100%'}}>All of this work comes together publicly in my project the ‘Yellow Squares’ which started in 2021 on Instagram, sharing ideas on building creative ecosystems. It has developed into a deep feedback loop of work and collaborations that has included an album, a visual installation, public billboards and countless remixes of my work. I am available for new commissions, and collaborations that expand this project.</p>
                            </div>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    {/* <a href="https://www.youtube.com/watch?v=jFGJzoexmME"> */}
                                        <img id="workThumbnail" style={{width: '200px', aspectRatio: '1', border: '2px solid black', mixBlendMode: 'normal', objectFit: 'cover'}} src={require('../Assets/360_1.jpeg')}></img>
                                    {/* </a> */}
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>360 Lecture</p>
                                        <p className="work-description">
                                        I collaborated with Lighthouse and The Old Market in Brighton to make an immersive version of my lecture, and album with my long time collaborator Royal-T working with me on the visuals. This is a work in progress we hope to bring to more cities in the future.
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://maketheting.bandcamp.com/album/make-the-ting">
                                        <img id="workThumbnail" style={{width: '200px', aspectRatio: '1', border: '2px solid black', mixBlendMode: 'darken'}} src={require('../Assets/MAKETHETING.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Make The Ting</p>
                                        <p className="work-description">
                                        I collaborated with Grime MC Jammz to make an album based on my writing in one week, which we released the acapellas for so other artists could remix. We pressed a vinyl edition of the album with a blank cover, and I drew custom artwork for every sale, making hundreds of different covers. Collaborations with Jammz will continue to stretch these ideas.
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://www.buildhollywood.co.uk/features/elijahs-yellow-squares-began-life-on-instagram-now-were-collaborating-with-him-as-part-of-your-space-or-mine-with-a-series-of-new-billboards/">
                                        <img id="workThumbnail" style={{width: '200px', aspectRatio: '1', border: '2px solid black', mixBlendMode: 'normal', objectFit: 'cover'}} src={require('../Assets/BILLBOARDMTT.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Billboards</p>
                                        <p className="work-description">
                                        I collaborated with <a className="inline-link" href="https://www.buildhollywood.co.uk/features/elijahs-yellow-squares-began-life-on-instagram-now-were-collaborating-with-him-as-part-of-your-space-or-mine-with-a-series-of-new-billboards/">Build Hollywood</a> to bring my writing into the real world, starting in my local community, then across London in 2023. I'm interested in working on projects that take very online ideas into the physical world.
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://drive.google.com/drive/folders/1QkxYOZM_fL38yko2r6rs7tuiF9hBS9ua?usp=sharing">
                                        <img id="workThumbnail" style={{width: '200px', aspectRatio: '1', border: '2px solid black'}} src={require('../Assets/ELIJAH ▢ - Specialist.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Yellow Squares Archive</p>
                                        <p className="work-description">
                                        You can <a className="inline-link" href="https://drive.google.com/drive/folders/1QkxYOZM_fL38yko2r6rs7tuiF9hBS9ua?usp=sharing">download the squares directly here</a>, and create your own lecture, art or ideas based on these. Just send me what you made. They are open source.
                                        </p>
                                    </div>
                                </div>
                                {/* <img style={{width: '40%', border: '3px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img> */}
                            </div>
                        </div>
                        <div style={{display: 'none', flexDirection: 'column', marginTop: '30px'}}>
                            <HeaderComponent title="OPEN SOURCE APPROACH" description="As an artist I explore an Open Source approach to creating and sharing through Music, DJing and Visual Installations."></HeaderComponent>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <a href="https://www.dropbox.com/scl/fo/womhmqrcduwwrg0jgn4u5/h?rlkey=2kwf722paf9h84b5egi84f9ru&dl=0">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/MAKETHETING.jpg')}></img>
                                </a>
                                {/* <img style={{width: '40%', border: '3px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img> */}
                            </div>
                        </div>
                        <div style={{display: 'none', flexDirection: 'column', marginTop: '30px'}}>
                            <HeaderComponent title="MUSICAL JOURNEY" description="My journey has included running a Grime record label, being a pirate radio host on Rinse FM and writing about Electronic Music Culture."></HeaderComponent>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <a href="https://ra.co/features/series/elijah">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/RA.jpg')}></img>
                                </a>
                                <a href="https://www.youtube.com/watch?v=f0gawKpIFgU">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/BUTTERZ.jpg')}></img>
                                </a>
                                <a href="https://www.youtube.com/watch?v=NbgTT3venF0">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/KEEPHUSH.jpg')}></img>
                                </a>
                                <a href="https://www.youtube.com/watch?v=dBhjzDh5Iew">
                                    {/* RA EXACHANGE POSTER */}
                                </a>
                                <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black'}} src={require('../Assets/ELIJAH ▢ - One More Thing.jpg')}></img>
                                {/* <img style={{width: '40%', border: '3px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img> */}
                            </div>
                        </div>
                    </div>
                    <div style={{width: '100%', marginBottom: '20px', textAlign: 'left', display: 'none'}}>
                        <div style={{marginBottom: '20px', width: '100%'}}>
                            <h id="subTitle" style={{fontSize: '40px', width: '100%', marginLeft: '0%', marginTop: '0px', textAlign: 'center', lineHeight: '40px'}}>360 Immersive Lecture @ Market House, Brighton</h>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', gap: '20px'}}>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_1.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_2.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_3.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                        </div>
                        <p style={{marginTop: '20px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </div>
            </div>
            </MediaQuery>

            <MediaQuery maxWidth={560} minWidth={401}>
            <div id="contentDiv">
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '92vw', textAlign: 'center', marginTop: '-30px'}}>
                    {/* SOCIAL MEDIA ITEMS */}
                    <div style={{display: 'flex', width: '100%', justifyContent: 'left'}}>
                        {/* <h1 style={{fontSize: '14px'}}>--- LINKS TO SOCIALS ---</h1> */}
                        {/* <div style={{display: 'flex',justifyContent: 'space-between', alignContent: 'space-between', width: '100%', flexDirection: 'row', marginBottom: '20px', marginTop: '50px'}}> */}
                        {/* <div style={{display: 'flex',justifyContent: 'center', maxWidth: '700' , textAlign: 'center', width: '100%', border: '1px solid red', flexDirection: 'row', marginBottom: '20px', marginTop: '50px'}}> */}
                        <div style={{display: 'flex', width: '100%', flexDirection: 'row', gap: '2px', marginBottom: '20px', marginTop: '50px'}}>
                            {/* <h1 style={{fontSize: '14px'}} id="hoverHeader">ELIJAH ASSETS</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">X</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">INSTAGRAM</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">YOUTUBE</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">EMAIL</h1> */}
                            <a href="https://x.com/Eli1ah">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/X.png')}></img>
                            </a>
                            <a href="https://instagram.com/eli1ah">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/INSTA.png')}></img>
                            </a>
                            <a href="https://www.youtube.com/channel/UChNAhXKwcjedUY2KNtIQSXg/videos">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/YOUTUBE.png')}></img>
                            </a>
                            {/* <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/EMAIL.png')}></img> */}
                            <a href="https://maketheting.bandcamp.com/album/make-the-ting">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/BANDCAMP.png')}></img>
                            </a>
                            <a href="https://open.spotify.com/artist/09ub5MJMcOlgG7WFbszSuf?si=TehfuOpGTx6GVXM6UvPaYw">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/SPOTIFY.png')}></img>
                            </a>
                        </div>
                    </div>
                    {/* INTRODUCTION ITEMS */}
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                            <h2 id="subTitle" style={{fontSize: '40px', width: '100%', marginLeft: '0%', marginTop: '0px', textAlign: 'left', lineHeight: '40px', fontWeight: '500'}}>I’m <span><img style={{width: '160px', mixBlendMode: 'multiply'}} src={require('../Assets/Elijah_Logo_24.png')}></img></span>, a creative consultant, lecturer and artist with 15 years experience in the Independent Music and Arts sector in London.</h2>
                            <div style={{marginTop: '-20px', display: 'flex', justifyContent: 'center'}}>
                                <p style={{width: '100%', fontSize: '15px', marginTop: '0px', textAlign: 'left'}}>Get in touch if you want to work on a new idea, want me to lecture at your company, institution or festival or would like to commission me as an artist or book me as a DJ. I occasionally do campaign work with brands I share values with.</p>
                            </div>
                        </div>
                        {/* <hr style={{width: '100vw', border: '0.7px solid', marginLeft: '-4vw'}}></hr> */}
                        <div style={{width: '100%', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '-10px'}}>
                            <h2 style={{fontSize: '21px', fontWeight: '450'}}>My work for companies and artists has covered:</h2>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '0px'}}>
                                <p id="paragraph" style={{marginTop: '-8px', fontSize: '15px' , fontWeight: 'normal', textAlign: 'left'}}><span style={{fontWeight: '700'}}>+</span> Artist Management for <a className="inline-link" href="https://youtu.be/QA2iOLDB7l8?si=_Bfls4jxjYgKZ4gQ">Flava D</a>, <a className="inline-link" href="https://youtu.be/GWVM8KHFQFI?si=UHrY4sg2aMXuR6yn">Swindle</a>, <a className="inline-link" href="https://youtu.be/IHWSd3rcH0c?si=pkrF3JJYlbHxZqGR">DJ Q</a>, <a className="inline-link" href="https://youtu.be/IHWSd3rcH0c?si=pkrF3JJYlbHxZqGR">TQD</a></p>
                                <p id="paragraph" style={{marginTop: '-10px', fontSize: '15px' , fontWeight: 'normal', textAlign: 'left'}}><span style={{fontWeight: '700'}}>+</span> Co Founding <a className="inline-link" href="https://youtu.be/f0gawKpIFgU?si=vuT8TJgT9T7lbS8S">Butterz, a record label & events brand [2010-2021]</a></p>
                                <p id="paragraph" style={{marginTop: '-10px', fontSize: '15px', fontWeight: 'normal', textAlign: 'left'}}><span style={{fontWeight: '700'}}>+</span> Brand Strategy, Product Development and Creative Direction</p>
                                <p id="paragraph" style={{marginTop: '-10px', fontSize: '15px', fontWeight: 'normal', textAlign: 'left'}}><span style={{fontWeight: '700'}}>+</span> Launching a <a className="inline-link" href="https://newsroom.tiktok.com/en-gb/tiktok-partners-youth-music-nextgen-fund">fund for 18-25 year old emerging musicians</a></p>
                            </div>
                        </div>
                    </div>
                    <hr style={{border: '1px solid black', width: '100vw', marginLeft: '-4vw', marginBottom: '4px'}}></hr>
                        <div style={{width: '100vw'}}>
                            <Marquee style={{fontSize: '10px'}}>
                                <p style={{marginTop: '0px', marginBottom: '0px', fontWeight: '350', fontSize: '10px'}}>Are the ideas still true at scale? You can't copyright vibe. Imposter syndrome is a feature not a bug. Close the app. Make the ting. The process is the art. Document your ting. It's not an ecosystem if everyone is doing the same thing. Social media is a canvas. Friends buy tickets. Create your own lecture. Make zero your favourite number. Practise makes more tings. It may all make sense in the end. Your plan assumes everything stays the same. More time you don't need more time. Time is the creative director. Nobody knows what's going to happen next. </p>
                            </Marquee>
                        </div>
                    <hr style={{border: '1px solid black', width: '100vw', marginLeft: '-4vw', marginTop: '4px'}}></hr>
                    {/* HEADER ITEMS */}
                    {/* <p style={{textAlign: 'left', width: '100%', marginBottom: '-14px'}}>Scroll right to view my projects</p> */}
                    <div className="work-section" style={{textAlign: 'center', display: 'flex', overflow: 'none', scrollbarWidth: 'none', flexDirection: 'column', columnGap: '20px', marginTop: '10px', width: '100%', marginBottom: '40px'}}>
                        {/* <HeaderComponent title="LECTURES" description="I’m an ambassador for V&A East, a community fellow at SOAS University, and have lectured globally on new ways of thinking about building creative careers, using social media as a canvas, and rethinking arts education."></HeaderComponent>
                        <HeaderComponent title="YELLOW SQUARES" description="All of this work comes together publicly in my project, the ‘Yellow Squares’ which started in 2021, sharing ideas on building creative ecosystems. It has developed into my feedback loop of work and collaboration that have had impact globally."></HeaderComponent>
                        <HeaderComponent title="OPEN SOURCE APPROACH" description="As an artist I explore an Open Source approach to creating and sharing through Music, DJing and Visual Installations."></HeaderComponent>
                        <HeaderComponent title="MUSICAL JOURNEY" description="My journey has included running a Grime record label, being a pirate radio host on Rinse FM and writing about Electronic Music Culture."></HeaderComponent> */}
                        {/* <h style={{fontSize: ''}}>ARCHIVE WORK</h>
                        <span style={{width: '100%', border: '1px solid black'}}></span> */}
                        <div style={{display: 'flex', marginTop: '-20px', flexDirection: 'column', height: '100%', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>
                            {/* <HeaderComponent title="LECTURES" description="I’m an ambassador for V&A East, a community fellow at SOAS University, and have lectured globally on new ways of thinking about building creative careers, using social media as a canvas, and rethinking arts education."></HeaderComponent> */}
                            <div style={{width: '100%', display: 'flex', textAlign: 'left', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '-24px'}}>
                                <h2 style={{width: '100%', fontSize: '30px', fontWeight: '600'}}>Lectures</h2>
                                <p style={{marginTop: '-10px', width: '100%'}}>I’m an ambassador for V&A East, a community fellow at SOAS University, and have lectured globally on new ways of thinking about building creative careers, using social media as a canvas, and rethinking arts education.</p>
                            </div>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://www.youtube.com/watch?v=aqgdArod1jU">
                                        <img id="workThumbnail" style={{width: '140px', aspectRatio: '1', border: '2px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Break Free</p>
                                        <p className="work-description" style={{fontSize: '14px'}}>
                                        An evolving talk and Q&A on creating with what you have, reflective of the core topics in the Yellow Square project on Instagram. Have done this in London, Auckland, Ho Chi Minh City, São Paulo, Tokyo, Istanbul, Ibiza, Barcelona, Oslo, Brussels, Berlin and across India.
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                <a href="https://youtu.be/balv1LICF_s?si=tNbVtdwDbC6EhCWv">
                                    <img id="workThumbnail" style={{width: '140px', aspectRatio: '1', border: '2px solid black'}} src={require('../Assets/CLOSETHEAPP.jpg')}></img>
                                </a>
                                <div>
                                    <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Close The App</p>
                                    <p className="work-description" style={{fontSize: '14px'}}>
                                        An alternative take on the wide possibilities of the future of music and DJ culture. New for 2024.
                                    </p>
                                </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                <a href="https://www.youtube.com/watch?v=jFGJzoexmME">
                                    <img id="workThumbnail" style={{width: '140px', aspectRatio: '1', border: '2px solid black', mixBlendMode: 'darken'}} src={require('../Assets/EMMAWARREN.jpg')}></img>
                                </a>
                                <div>
                                    <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Back to Back with Emma Warren</p>
                                    <p className="work-description" style={{fontSize: '14px'}}>
                                        A collaboration lecture with a fellow artist with me interrogating their work using my Yellow Square design language. These are one off specials. Watch Back To Back with Emma Warren at SOAS.
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                        <hr style={{width: '100vw', border: '1px solid black'}}></hr>
                        <div style={{display: 'flex', flexDirection: 'column', height: '100%', marginTop: '-5px', alignItems: 'center', justifyContent: 'center'}}>
                            {/* <HeaderComponent title="YELLOW SQUARES" description="All of this work comes together publicly in my project the ‘Yellow Squares’ which started in 2021 on Instagram, sharing ideas on building creative ecosystems. It has developed into a deep feedback loop of work and collaborations that has included an album, a visual installation, public billboards and countless remixes of my work. I am available for new commissions, and collaborations that expand this project."></HeaderComponent> */}
                            <div style={{width: '100%', display: 'flex', textAlign: 'left', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '-24px'}}>
                                <h2 style={{width: '100%', fontSize: '30px', fontWeight: '600'}}>Yellow Squares</h2>
                                <p style={{marginTop: '-10px', width: '100%'}}>All of this work comes together publicly in my project the ‘Yellow Squares’ which started in 2021 on Instagram, sharing ideas on building creative ecosystems. It has developed into a deep feedback loop of work and collaborations that has included an album, a visual installation, public billboards and countless remixes of my work. I am available for new commissions, and collaborations that expand this project.</p>
                            </div>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    {/* <a href="https://www.youtube.com/watch?v=jFGJzoexmME"> */}
                                        <img id="workThumbnail" style={{width: '140px', aspectRatio: '1', border: '2px solid black', mixBlendMode: 'normal', objectFit: 'cover'}} src={require('../Assets/360_1.jpeg')}></img>
                                    {/* </a> */}
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>360 Lecture</p>
                                        <p className="work-description" style={{fontSize: '14px'}}>
                                        I collaborated with Lighthouse and The Old Market in Brighton to make an immersive version of my lecture, and album with my long time collaborator Royal-T working with me on the visuals. This is a work in progress we hope to bring to more cities in the future.
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://maketheting.bandcamp.com/album/make-the-ting">
                                        <img id="workThumbnail" style={{width: '140px', aspectRatio: '1', border: '2px solid black', mixBlendMode: 'darken'}} src={require('../Assets/MAKETHETING.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Make The Ting</p>
                                        <p className="work-description" style={{fontSize: '14px'}}>
                                        I collaborated with Grime MC Jammz to make an album based on my writing in one week, which we released the acapellas for so other artists could remix. We pressed a vinyl edition of the album with a blank cover, and I drew custom artwork for every sale, making hundreds of different covers. Collaborations with Jammz will continue to stretch these ideas.
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://www.buildhollywood.co.uk/features/elijahs-yellow-squares-began-life-on-instagram-now-were-collaborating-with-him-as-part-of-your-space-or-mine-with-a-series-of-new-billboards/">
                                        <img id="workThumbnail" style={{width: '140px', aspectRatio: '1', border: '2px solid black', mixBlendMode: 'normal', objectFit: 'cover'}} src={require('../Assets/BILLBOARDMTT.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Billboards</p>
                                        <p className="work-description" style={{fontSize: '14px'}}>
                                        I collaborated with <a className="inline-link" href="https://www.buildhollywood.co.uk/features/elijahs-yellow-squares-began-life-on-instagram-now-were-collaborating-with-him-as-part-of-your-space-or-mine-with-a-series-of-new-billboards/">Build Hollywood</a> to bring my writing into the real world, starting in my local community, then across London in 2023. I'm interested in working on projects that take very online ideas into the physical world.
                                        </p>
                                    </div>
                                </div>
                                {/* <hr style={{width: '100vw', border: '1px solid black'}}></hr> */}
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://drive.google.com/drive/folders/1QkxYOZM_fL38yko2r6rs7tuiF9hBS9ua?usp=sharing">
                                        <img id="workThumbnail" style={{width: '140px', aspectRatio: '1', border: '2px solid black'}} src={require('../Assets/ELIJAH ▢ - Specialist.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Yellow Squares Archive</p>
                                        <p className="work-description" style={{fontSize: '14px'}}>
                                        You can <a className="inline-link" href="https://drive.google.com/drive/folders/1QkxYOZM_fL38yko2r6rs7tuiF9hBS9ua?usp=sharing">download the squares directly here</a>, and create your own lecture, art or ideas based on these. Just send me what you made. They are open source.
                                        </p>
                                    </div>
                                </div>
                                {/* <img style={{width: '40%', border: '3px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img> */}
                            </div>
                        </div>
                        <div style={{display: 'none', flexDirection: 'column', marginTop: '30px'}}>
                            <HeaderComponent title="OPEN SOURCE APPROACH" description="As an artist I explore an Open Source approach to creating and sharing through Music, DJing and Visual Installations."></HeaderComponent>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <a href="https://www.dropbox.com/scl/fo/womhmqrcduwwrg0jgn4u5/h?rlkey=2kwf722paf9h84b5egi84f9ru&dl=0">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/MAKETHETING.jpg')}></img>
                                </a>
                                {/* <img style={{width: '40%', border: '3px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img> */}
                            </div>
                        </div>
                        <div style={{display: 'none', flexDirection: 'column', marginTop: '30px'}}>
                            <HeaderComponent title="MUSICAL JOURNEY" description="My journey has included running a Grime record label, being a pirate radio host on Rinse FM and writing about Electronic Music Culture."></HeaderComponent>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <a href="https://ra.co/features/series/elijah">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/RA.jpg')}></img>
                                </a>
                                <a href="https://www.youtube.com/watch?v=f0gawKpIFgU">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/BUTTERZ.jpg')}></img>
                                </a>
                                <a href="https://www.youtube.com/watch?v=NbgTT3venF0">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/KEEPHUSH.jpg')}></img>
                                </a>
                                <a href="https://www.youtube.com/watch?v=dBhjzDh5Iew">
                                    {/* RA EXACHANGE POSTER */}
                                </a>
                                <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black'}} src={require('../Assets/ELIJAH ▢ - One More Thing.jpg')}></img>
                                {/* <img style={{width: '40%', border: '3px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img> */}
                            </div>
                        </div>
                    </div>
                    <div style={{width: '100%', marginBottom: '20px', textAlign: 'left', display: 'none'}}>
                        <div style={{marginBottom: '20px', width: '100%'}}>
                            <h id="subTitle" style={{fontSize: '40px', width: '100%', marginLeft: '0%', marginTop: '0px', textAlign: 'center', lineHeight: '40px'}}>360 Immersive Lecture @ Market House, Brighton</h>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', gap: '20px'}}>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_1.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_2.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_3.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                        </div>
                        <p style={{marginTop: '20px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </div>
            </div>
            </MediaQuery>

            <MediaQuery maxWidth={400}>
            <div id="contentDiv">
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '92vw', textAlign: 'center', marginTop: '-30px'}}>
                    {/* SOCIAL MEDIA ITEMS */}
                    <div style={{display: 'flex', width: '100%', justifyContent: 'left'}}>
                        {/* <h1 style={{fontSize: '14px'}}>--- LINKS TO SOCIALS ---</h1> */}
                        {/* <div style={{display: 'flex',justifyContent: 'space-between', alignContent: 'space-between', width: '100%', flexDirection: 'row', marginBottom: '20px', marginTop: '50px'}}> */}
                        {/* <div style={{display: 'flex',justifyContent: 'center', maxWidth: '700' , textAlign: 'center', width: '100%', border: '1px solid red', flexDirection: 'row', marginBottom: '20px', marginTop: '50px'}}> */}
                        <div style={{display: 'flex', width: '100%', flexDirection: 'row', gap: '2px', marginBottom: '20px', marginTop: '50px'}}>
                            {/* <h1 style={{fontSize: '14px'}} id="hoverHeader">ELIJAH ASSETS</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">X</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">INSTAGRAM</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">YOUTUBE</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">EMAIL</h1> */}
                            <a href="https://x.com/Eli1ah">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/X.png')}></img>
                            </a>
                            <a href="https://instagram.com/eli1ah">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/INSTA.png')}></img>
                            </a>
                            <a href="https://www.youtube.com/channel/UChNAhXKwcjedUY2KNtIQSXg/videos">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/YOUTUBE.png')}></img>
                            </a>
                            {/* <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/EMAIL.png')}></img> */}
                            <a href="https://maketheting.bandcamp.com/album/make-the-ting">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/BANDCAMP.png')}></img>
                            </a>
                            <a href="https://open.spotify.com/artist/09ub5MJMcOlgG7WFbszSuf?si=TehfuOpGTx6GVXM6UvPaYw">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/SPOTIFY.png')}></img>
                            </a>
                        </div>
                    </div>
                    {/* INTRODUCTION ITEMS */}
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                            <h2 id="subTitle" style={{fontSize: '40px', width: '100%', marginLeft: '0%', marginTop: '0px', textAlign: 'left', lineHeight: '40px', fontWeight: '500'}}>I’m <span><img style={{width: '160px', mixBlendMode: 'multiply'}} src={require('../Assets/Elijah_Logo_24.png')}></img></span>, a creative consultant, lecturer and artist with 15 years experience in the Independent Music and Arts sector in London.</h2>
                            <div style={{marginTop: '-20px', display: 'flex', justifyContent: 'center'}}>
                                <p style={{width: '100%', fontSize: '15px', marginTop: '0px', textAlign: 'left'}}>Get in touch if you want to work on a new idea, want me to lecture at your company, institution or festival or would like to commission me as an artist or book me as a DJ. I occasionally do campaign work with brands I share values with.</p>
                            </div>
                        </div>
                        {/* <hr style={{width: '100vw', border: '0.7px solid', marginLeft: '-4vw'}}></hr> */}
                        <div style={{width: '100%', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '-10px'}}>
                            <h2 style={{fontSize: '21px', fontWeight: '450'}}>My work for companies and artists has covered:</h2>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '0px'}}>
                                <p id="paragraph" style={{marginTop: '-8px', fontSize: '15px' , fontWeight: 'normal', textAlign: 'left'}}><span style={{fontWeight: '700'}}>+</span> Artist Management for <a className="inline-link" href="https://youtu.be/QA2iOLDB7l8?si=_Bfls4jxjYgKZ4gQ">Flava D</a>, <a className="inline-link" href="https://youtu.be/GWVM8KHFQFI?si=UHrY4sg2aMXuR6yn">Swindle</a>, <a className="inline-link" href="https://youtu.be/IHWSd3rcH0c?si=pkrF3JJYlbHxZqGR">DJ Q</a>, <a className="inline-link" href="https://youtu.be/IHWSd3rcH0c?si=pkrF3JJYlbHxZqGR">TQD</a></p>
                                <p id="paragraph" style={{marginTop: '-10px', fontSize: '15px' , fontWeight: 'normal', textAlign: 'left'}}><span style={{fontWeight: '700'}}>+</span> Co Founding <a className="inline-link" href="https://youtu.be/f0gawKpIFgU?si=vuT8TJgT9T7lbS8S">Butterz, a record label & events brand [2010-2021]</a></p>
                                <p id="paragraph" style={{marginTop: '-10px', fontSize: '15px', fontWeight: 'normal', textAlign: 'left'}}><span style={{fontWeight: '700'}}>+</span> Brand Strategy, Product Development and Creative Direction</p>
                                <p id="paragraph" style={{marginTop: '-10px', fontSize: '15px', fontWeight: 'normal', textAlign: 'left'}}><span style={{fontWeight: '700'}}>+</span> Launching a <a className="inline-link" href="https://newsroom.tiktok.com/en-gb/tiktok-partners-youth-music-nextgen-fund">fund for 18-25 year old emerging musicians</a></p>
                            </div>
                        </div>
                    </div>
                    <hr style={{border: '1px solid black', width: '100vw', marginLeft: '-4vw', marginBottom: '4px'}}></hr>
                        <div style={{width: '100vw'}}>
                            <Marquee style={{fontSize: '10px'}}>
                                <p style={{marginTop: '0px', marginBottom: '0px', fontWeight: '350', fontSize: '10px'}}>Are the ideas still true at scale? You can't copyright vibe. Imposter syndrome is a feature not a bug. Close the app. Make the ting. The process is the art. Document your ting. It's not an ecosystem if everyone is doing the same thing. Social media is a canvas. Friends buy tickets. Create your own lecture. Make zero your favourite number. Practise makes more tings. It may all make sense in the end. Your plan assumes everything stays the same. More time you don't need more time. Time is the creative director. Nobody knows what's going to happen next. </p>
                            </Marquee>
                        </div>
                    <hr style={{border: '1px solid black', width: '100vw', marginLeft: '-4vw', marginTop: '4px'}}></hr>
                    {/* HEADER ITEMS */}
                    {/* <p style={{textAlign: 'left', width: '100%', marginBottom: '-14px'}}>Scroll right to view my projects</p> */}
                    <div className="work-section" style={{textAlign: 'center', display: 'flex', overflow: 'none', scrollbarWidth: 'none', flexDirection: 'column', columnGap: '20px', marginTop: '10px', width: '100%', marginBottom: '40px'}}>
                        {/* <HeaderComponent title="LECTURES" description="I’m an ambassador for V&A East, a community fellow at SOAS University, and have lectured globally on new ways of thinking about building creative careers, using social media as a canvas, and rethinking arts education."></HeaderComponent>
                        <HeaderComponent title="YELLOW SQUARES" description="All of this work comes together publicly in my project, the ‘Yellow Squares’ which started in 2021, sharing ideas on building creative ecosystems. It has developed into my feedback loop of work and collaboration that have had impact globally."></HeaderComponent>
                        <HeaderComponent title="OPEN SOURCE APPROACH" description="As an artist I explore an Open Source approach to creating and sharing through Music, DJing and Visual Installations."></HeaderComponent>
                        <HeaderComponent title="MUSICAL JOURNEY" description="My journey has included running a Grime record label, being a pirate radio host on Rinse FM and writing about Electronic Music Culture."></HeaderComponent> */}
                        {/* <h style={{fontSize: ''}}>ARCHIVE WORK</h>
                        <span style={{width: '100%', border: '1px solid black'}}></span> */}
                        <div style={{display: 'flex', marginTop: '-20px', flexDirection: 'column', height: '100%', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>
                            {/* <HeaderComponent title="LECTURES" description="I’m an ambassador for V&A East, a community fellow at SOAS University, and have lectured globally on new ways of thinking about building creative careers, using social media as a canvas, and rethinking arts education."></HeaderComponent> */}
                            <div style={{width: '100%', display: 'flex', textAlign: 'left', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '-24px'}}>
                                <h2 style={{width: '100%', fontSize: '30px', fontWeight: '600'}}>Lectures</h2>
                                <p style={{marginTop: '-10px', width: '100%'}}>I’m an ambassador for V&A East, a community fellow at SOAS University, and have lectured globally on new ways of thinking about building creative careers, using social media as a canvas, and rethinking arts education.</p>
                            </div>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://www.youtube.com/watch?v=aqgdArod1jU">
                                        <img id="workThumbnail" style={{width: '120px', aspectRatio: '1', border: '2px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Break Free</p>
                                        <p className="work-description" style={{fontSize: '14px'}}>
                                        An evolving talk and Q&A on creating with what you have, reflective of the core topics in the Yellow Square project on Instagram. Have done this in London, Auckland, Ho Chi Minh City, São Paulo, Tokyo, Istanbul, Ibiza, Barcelona, Oslo, Brussels, Berlin and across India.
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                <a href="https://youtu.be/balv1LICF_s?si=tNbVtdwDbC6EhCWv">
                                    <img id="workThumbnail" style={{width: '120px', aspectRatio: '1', border: '2px solid black'}} src={require('../Assets/CLOSETHEAPP.jpg')}></img>
                                </a>
                                <div>
                                    <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Close The App</p>
                                    <p className="work-description" style={{fontSize: '14px'}}>
                                        An alternative take on the wide possibilities of the future of music and DJ culture. New for 2024.
                                    </p>
                                </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                <a href="https://www.youtube.com/watch?v=jFGJzoexmME">
                                    <img id="workThumbnail" style={{width: '120px', aspectRatio: '1', border: '2px solid black', mixBlendMode: 'darken'}} src={require('../Assets/EMMAWARREN.jpg')}></img>
                                </a>
                                <div>
                                    <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Back to Back with Emma Warren</p>
                                    <p className="work-description" style={{fontSize: '14px'}}>
                                        A collaboration lecture with a fellow artist with me interrogating their work using my Yellow Square design language. These are one off specials. Watch Back To Back with Emma Warren at SOAS.
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                        <hr style={{width: '100vw', border: '1px solid black'}}></hr>
                        <div style={{display: 'flex', flexDirection: 'column', height: '100%', marginTop: '-5px', alignItems: 'center', justifyContent: 'center'}}>
                            {/* <HeaderComponent title="YELLOW SQUARES" description="All of this work comes together publicly in my project the ‘Yellow Squares’ which started in 2021 on Instagram, sharing ideas on building creative ecosystems. It has developed into a deep feedback loop of work and collaborations that has included an album, a visual installation, public billboards and countless remixes of my work. I am available for new commissions, and collaborations that expand this project."></HeaderComponent> */}
                            <div style={{width: '100%', display: 'flex', textAlign: 'left', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '-24px'}}>
                                <h2 style={{width: '100%', fontSize: '30px', fontWeight: '600'}}>Yellow Squares</h2>
                                <p style={{marginTop: '-10px', width: '100%'}}>All of this work comes together publicly in my project the ‘Yellow Squares’ which started in 2021 on Instagram, sharing ideas on building creative ecosystems. It has developed into a deep feedback loop of work and collaborations that has included an album, a visual installation, public billboards and countless remixes of my work. I am available for new commissions, and collaborations that expand this project.</p>
                            </div>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    {/* <a href="https://www.youtube.com/watch?v=jFGJzoexmME"> */}
                                        <img id="workThumbnail" style={{width: '120px', aspectRatio: '1', border: '2px solid black', mixBlendMode: 'normal', objectFit: 'cover'}} src={require('../Assets/360_1.jpeg')}></img>
                                    {/* </a> */}
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>360 Lecture</p>
                                        <p className="work-description" style={{fontSize: '14px'}}>
                                        I collaborated with Lighthouse and The Old Market in Brighton to make an immersive version of my lecture, and album with my long time collaborator Royal-T working with me on the visuals. This is a work in progress we hope to bring to more cities in the future.
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://maketheting.bandcamp.com/album/make-the-ting">
                                        <img id="workThumbnail" style={{width: '120px', aspectRatio: '1', border: '2px solid black', mixBlendMode: 'darken'}} src={require('../Assets/MAKETHETING.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Make The Ting</p>
                                        <p className="work-description" style={{fontSize: '14px'}}>
                                        I collaborated with Grime MC Jammz to make an album based on my writing in one week, which we released the acapellas for so other artists could remix. We pressed a vinyl edition of the album with a blank cover, and I drew custom artwork for every sale, making hundreds of different covers. Collaborations with Jammz will continue to stretch these ideas.
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://www.buildhollywood.co.uk/features/elijahs-yellow-squares-began-life-on-instagram-now-were-collaborating-with-him-as-part-of-your-space-or-mine-with-a-series-of-new-billboards/">
                                        <img id="workThumbnail" style={{width: '120px', aspectRatio: '1', border: '2px solid black', mixBlendMode: 'normal', objectFit: 'cover'}} src={require('../Assets/BILLBOARDMTT.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Billboards</p>
                                        <p className="work-description" style={{fontSize: '14px'}}>
                                        I collaborated with <a className="inline-link" href="https://www.buildhollywood.co.uk/features/elijahs-yellow-squares-began-life-on-instagram-now-were-collaborating-with-him-as-part-of-your-space-or-mine-with-a-series-of-new-billboards/">Build Hollywood</a> to bring my writing into the real world, starting in my local community, then across London in 2023. I'm interested in working on projects that take very online ideas into the physical world.
                                        </p>
                                    </div>
                                </div>
                                {/* <hr style={{width: '100vw', border: '1px solid black'}}></hr> */}
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <a href="https://drive.google.com/drive/folders/1QkxYOZM_fL38yko2r6rs7tuiF9hBS9ua?usp=sharing">
                                        <img id="workThumbnail" style={{width: '120px', aspectRatio: '1', border: '2px solid black'}} src={require('../Assets/ELIJAH ▢ - Specialist.jpg')}></img>
                                    </a>
                                    <div>
                                        <p className="work-title" style={{fontSize: '19px', marginBottom: '-8px', fontWeight: '600'}}>Yellow Squares Archive</p>
                                        <p className="work-description" style={{fontSize: '14px'}}>
                                        You can <a className="inline-link" href="https://drive.google.com/drive/folders/1QkxYOZM_fL38yko2r6rs7tuiF9hBS9ua?usp=sharing">download the squares directly here</a>, and create your own lecture, art or ideas based on these. Just send me what you made. They are open source.
                                        </p>
                                    </div>
                                </div>
                                {/* <img style={{width: '40%', border: '3px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img> */}
                            </div>
                        </div>
                        <div style={{display: 'none', flexDirection: 'column', marginTop: '30px'}}>
                            <HeaderComponent title="OPEN SOURCE APPROACH" description="As an artist I explore an Open Source approach to creating and sharing through Music, DJing and Visual Installations."></HeaderComponent>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <a href="https://www.dropbox.com/scl/fo/womhmqrcduwwrg0jgn4u5/h?rlkey=2kwf722paf9h84b5egi84f9ru&dl=0">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/MAKETHETING.jpg')}></img>
                                </a>
                                {/* <img style={{width: '40%', border: '3px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img> */}
                            </div>
                        </div>
                        <div style={{display: 'none', flexDirection: 'column', marginTop: '30px'}}>
                            <HeaderComponent title="MUSICAL JOURNEY" description="My journey has included running a Grime record label, being a pirate radio host on Rinse FM and writing about Electronic Music Culture."></HeaderComponent>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <a href="https://ra.co/features/series/elijah">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/RA.jpg')}></img>
                                </a>
                                <a href="https://www.youtube.com/watch?v=f0gawKpIFgU">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/BUTTERZ.jpg')}></img>
                                </a>
                                <a href="https://www.youtube.com/watch?v=NbgTT3venF0">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/KEEPHUSH.jpg')}></img>
                                </a>
                                <a href="https://www.youtube.com/watch?v=dBhjzDh5Iew">
                                    {/* RA EXACHANGE POSTER */}
                                </a>
                                <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black'}} src={require('../Assets/ELIJAH ▢ - One More Thing.jpg')}></img>
                                {/* <img style={{width: '40%', border: '3px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img> */}
                            </div>
                        </div>
                    </div>
                    <div style={{width: '100%', marginBottom: '20px', textAlign: 'left', display: 'none'}}>
                        <div style={{marginBottom: '20px', width: '100%'}}>
                            <h id="subTitle" style={{fontSize: '40px', width: '100%', marginLeft: '0%', marginTop: '0px', textAlign: 'center', lineHeight: '40px'}}>360 Immersive Lecture @ Market House, Brighton</h>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', gap: '20px'}}>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_1.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_2.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_3.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                        </div>
                        <p style={{marginTop: '20px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </div>
            </div>
            </MediaQuery>

            <MediaQuery maxWidth={60}>
            <div id="contentDiv">
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '92vw', textAlign: 'center', marginTop: '-30px'}}>
                    {/* SOCIAL MEDIA ITEMS */}
                    <div style={{display: 'flex', width: '100%', justifyContent: 'left'}}>
                        {/* <h1 style={{fontSize: '14px'}}>--- LINKS TO SOCIALS ---</h1> */}
                        {/* <div style={{display: 'flex',justifyContent: 'space-between', alignContent: 'space-between', width: '100%', flexDirection: 'row', marginBottom: '20px', marginTop: '50px'}}> */}
                        {/* <div style={{display: 'flex',justifyContent: 'center', maxWidth: '700' , textAlign: 'center', width: '100%', border: '1px solid red', flexDirection: 'row', marginBottom: '20px', marginTop: '50px'}}> */}
                        <div style={{display: 'flex', width: '100%', flexDirection: 'row', gap: '2px', marginBottom: '20px', marginTop: '50px'}}>
                            {/* <h1 style={{fontSize: '14px'}} id="hoverHeader">ELIJAH ASSETS</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">X</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">INSTAGRAM</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">YOUTUBE</h1>
                            <h1 style={{fontSize: '14px'}} id="hoverHeader">EMAIL</h1> */}
                            <a href="https://x.com/Eli1ah">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/X.png')}></img>
                            </a>
                            <a href="https://instagram.com/eli1ah">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/INSTA.png')}></img>
                            </a>
                            <a href="https://www.youtube.com/channel/UChNAhXKwcjedUY2KNtIQSXg/videos">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/YOUTUBE.png')}></img>
                            </a>
                            {/* <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/EMAIL.png')}></img> */}
                            <a href="https://maketheting.bandcamp.com/album/make-the-ting">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/BANDCAMP.png')}></img>
                            </a>
                            <a href="https://open.spotify.com/artist/09ub5MJMcOlgG7WFbszSuf?si=TehfuOpGTx6GVXM6UvPaYw">
                                <img style={{width: '40px', aspectRatio: '1'}} src={require('../Assets/SOCIAL_MEDIA_ICONS/SPOTIFY.png')}></img>
                            </a>
                        </div>
                    </div>
                    {/* INTRODUCTION ITEMS */}
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                            <h2 id="subTitle" style={{fontSize: '40px', width: '100%', marginLeft: '0%', marginTop: '0px', textAlign: 'left', lineHeight: '40px'}}>I’m <span><img style={{width: '160px', mixBlendMode: 'multiply'}} src={require('../Assets/Elijah_Logo_24.png')}></img></span>, a creative consultant, lecturer and artist with 15 years experience in the Independent Music and Arts sector in London.</h2>
                            <div style={{marginTop: '-20px', display: 'flex', justifyContent: 'center'}}>
                                <p style={{width: '100%', fontSize: '15px', marginTop: '0px', textAlign: 'left'}}>Get in touch if you want to work on a new idea, want me to lecture at your company, institution or festival or would like to commission me as an artist or book me as a DJ. I occasionally do campaign work with brands I share values with.</p>
                            </div>
                        </div>
                        <div style={{width: '100%', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '-10px'}}>
                            <h2 style={{fontSize: '22px'}}>My work for companies and artists has covered:</h2>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '0px'}}>
                                <p id="paragraph" style={{marginTop: '-8px', fontSize: '15px' , fontWeight: 'normal', textAlign: 'left'}}>Artist Management for <a className="inline-link" href="https://youtu.be/QA2iOLDB7l8?si=_Bfls4jxjYgKZ4gQ">Flava D</a>, <a className="inline-link" href="https://youtu.be/GWVM8KHFQFI?si=UHrY4sg2aMXuR6yn">Swindle</a>, <a className="inline-link" href="https://youtu.be/IHWSd3rcH0c?si=pkrF3JJYlbHxZqGR">DJ Q</a>, <a className="inline-link" href="https://youtu.be/IHWSd3rcH0c?si=pkrF3JJYlbHxZqGR">TQD</a></p>
                                <p id="paragraph" style={{marginTop: '-10px', fontSize: '15px' , fontWeight: 'normal', textAlign: 'left'}}>Co Founding <a className="inline-link" href="https://youtu.be/f0gawKpIFgU?si=vuT8TJgT9T7lbS8S">Butterz, a record label & events brand [2010-2021]</a></p>
                                <p id="paragraph" style={{marginTop: '-10px', fontSize: '15px', fontWeight: 'normal', textAlign: 'left'}}>Brand Strategy, Product Development and Creative Direction</p>
                                <p id="paragraph" style={{marginTop: '-10px', fontSize: '15px', fontWeight: 'normal', textAlign: 'left'}}>Launching a <a className="inline-link" href="https://newsroom.tiktok.com/en-gb/tiktok-partners-youth-music-nextgen-fund">fund for 18-25 year old emerging musicians</a></p>
                            </div>
                        </div>
                    </div>
                    {/* HEADER ITEMS */}
                    {/* <p style={{textAlign: 'left', width: '100%', marginBottom: '-14px'}}>Scroll right to view my projects</p> */}
                    <div className="work-section" style={{textAlign: 'center', display: 'flex', overflowX: 'auto', msOverflowStyle: 'none', scrollbarWidth: 'none', flexDirection: 'column', columnGap: '20px', marginTop: '20px', width: '90vw', marginBottom: '40px'}}>
                        {/* <HeaderComponent title="LECTURES" description="I’m an ambassador for V&A East, a community fellow at SOAS University, and have lectured globally on new ways of thinking about building creative careers, using social media as a canvas, and rethinking arts education."></HeaderComponent>
                        <HeaderComponent title="YELLOW SQUARES" description="All of this work comes together publicly in my project, the ‘Yellow Squares’ which started in 2021, sharing ideas on building creative ecosystems. It has developed into my feedback loop of work and collaboration that have had impact globally."></HeaderComponent>
                        <HeaderComponent title="OPEN SOURCE APPROACH" description="As an artist I explore an Open Source approach to creating and sharing through Music, DJing and Visual Installations."></HeaderComponent>
                        <HeaderComponent title="MUSICAL JOURNEY" description="My journey has included running a Grime record label, being a pirate radio host on Rinse FM and writing about Electronic Music Culture."></HeaderComponent> */}

                        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            <HeaderComponent title="LECTURES" description="I’m an ambassador for V&A East, a community fellow at SOAS University, and have lectured globally on new ways of thinking about building creative careers, using social media as a canvas, and rethinking arts education."></HeaderComponent>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', width: '95%'}}>
                                    <a href="https://www.youtube.com/watch?v=aqgdArod1jU">
                                        <img id="workThumbnail" style={{width: '200px', aspectRatio: '1', border: '3px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img>
                                    </a>
                                    <p>
                                    An evolving talk and Q&A on creating with what you have, reflective of the core topics in the Yellow Square project on Instagram. Have done this in London, Auckland, Ho Chi Minh City, São Paulo, Tokyo, Istanbul, Ibiza, Barcelona, Oslo, Brussels, Berlin and across India.
                                    </p>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', width: '95%'}}>
                                <a href="https://youtu.be/balv1LICF_s?si=tNbVtdwDbC6EhCWv">
                                    <img id="workThumbnail" style={{width: '200px', aspectRatio: '1', border: '3px solid black'}} src={require('../Assets/CLOSETHEAPP.jpg')}></img>
                                </a>
                                <p>
                                    An alternative take on the wide possibilities of the future of music and DJ culture. New for 2024.
                                    </p>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', width: '95%'}}>
                                <a href="https://www.youtube.com/watch?v=jFGJzoexmME">
                                    <img id="workThumbnail" style={{width: '200px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/EMMAWARREN.jpg')}></img>
                                </a>
                                <p>
                                    A collaboration lecture with a fellow artist with me interrogating their work using my Yellow Square design language. These are one off specials. Watch Back To Back with Emma Warren at SOAS.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', height: '100%', marginTop: '30px'}}>
                            <HeaderComponent title="YELLOW SQUARES" description="All of this work comes together publicly in my project the ‘Yellow Squares’ which started in 2021 on Instagram, sharing ideas on building creative ecosystems. It has developed into a deep feedback loop of work and collaborations that has included an album, a visual installation, public billboards and countless remixes of my work. I am available for new commissions, and collaborations that expand this project."></HeaderComponent>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '95%'}}>
                                    {/* <p style={{fontSize: '30px', fontWeight: 'bolder', marginBottom: '10px'}}>360 LECTURE</p> */}
                                    {/* <a href="https://www.youtube.com/watch?v=jFGJzoexmME"> */}
                                        <img id="workThumbnail" style={{width: '200px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'normal', objectFit: 'cover'}} src={require('../Assets/360_1.jpeg')}></img>
                                    {/* </a> */}
                                    <p>
                                    I collaborated with Lighthouse and The Old Market in Brighton to make an immersive version of my lecture, and album with my long time collaborator Royal-T working with me on the visuals. This is a work in progress we hope to bring to more cities in the future.
                                    </p>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', width: '95%'}}>
                                    <a href="https://maketheting.bandcamp.com/album/make-the-ting">
                                        <img id="workThumbnail" style={{width: '200px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/MAKETHETING.jpg')}></img>
                                    </a>
                                    <p>
                                    I collaborated with Grime MC Jammz to make an album based on my writing in one week, which we released the acapellas for so other artists could remix. We pressed a vinyl edition of the album with a blank cover, and I drew custom artwork for every sale, making hundreds of different covers. Collaborations with Jammz will continue to stretch these ideas.
                                    </p>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', width: '95%'}}>
                                    <a href="https://www.buildhollywood.co.uk/features/elijahs-yellow-squares-began-life-on-instagram-now-were-collaborating-with-him-as-part-of-your-space-or-mine-with-a-series-of-new-billboards/">
                                        <img id="workThumbnail" style={{width: '200px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'normal', objectFit: 'cover'}} src={require('../Assets/BILLBOARDMTT.jpg')}></img>
                                    </a>
                                    <p>
                                    I collaborated with <a className="inline-link" href="https://www.buildhollywood.co.uk/features/elijahs-yellow-squares-began-life-on-instagram-now-were-collaborating-with-him-as-part-of-your-space-or-mine-with-a-series-of-new-billboards/">Build Hollywood</a> to bring my writing into the real world, starting in my local community, then across London in 2023. I'm interested in working on projects that take very online ideas into the physical world.
                                    </p>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', width: '95%'}}>
                                    <a href="https://drive.google.com/drive/folders/1QkxYOZM_fL38yko2r6rs7tuiF9hBS9ua?usp=sharing">
                                        <img id="workThumbnail" style={{width: '200px', aspectRatio: '1', border: '3px solid black'}} src={require('../Assets/ELIJAH ▢ - Specialist.jpg')}></img>
                                    </a>
                                    <p>
                                    You can <a className="inline-link" href="https://drive.google.com/drive/folders/1QkxYOZM_fL38yko2r6rs7tuiF9hBS9ua?usp=sharing">download the squares directly here</a>, and create your own lecture, art or ideas based on these. Just send me what you made. They are open source.
                                    </p>
                                </div>
                                {/* <img style={{width: '40%', border: '3px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img> */}
                            </div>
                        </div>
                        <div style={{display: 'none', flexDirection: 'column', marginTop: '30px'}}>
                            <HeaderComponent title="OPEN SOURCE APPROACH" description="As an artist I explore an Open Source approach to creating and sharing through Music, DJing and Visual Installations."></HeaderComponent>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <a href="https://www.dropbox.com/scl/fo/womhmqrcduwwrg0jgn4u5/h?rlkey=2kwf722paf9h84b5egi84f9ru&dl=0">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/MAKETHETING.jpg')}></img>
                                </a>
                                {/* <img style={{width: '40%', border: '3px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img> */}
                            </div>
                        </div>
                        <div style={{display: 'none', flexDirection: 'column', marginTop: '30px'}}>
                            <HeaderComponent title="MUSICAL JOURNEY" description="My journey has included running a Grime record label, being a pirate radio host on Rinse FM and writing about Electronic Music Culture."></HeaderComponent>
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                <a href="https://ra.co/features/series/elijah">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/RA.jpg')}></img>
                                </a>
                                <a href="https://www.youtube.com/watch?v=f0gawKpIFgU">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/BUTTERZ.jpg')}></img>
                                </a>
                                <a href="https://www.youtube.com/watch?v=NbgTT3venF0">
                                    <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black', mixBlendMode: 'darken'}} src={require('../Assets/KEEPHUSH.jpg')}></img>
                                </a>
                                <a href="https://www.youtube.com/watch?v=dBhjzDh5Iew">
                                    {/* RA EXACHANGE POSTER */}
                                </a>
                                <img id="workThumbnail" style={{width: '148px', aspectRatio: '1', border: '3px solid black'}} src={require('../Assets/ELIJAH ▢ - One More Thing.jpg')}></img>
                                {/* <img style={{width: '40%', border: '3px solid black'}} src={require('../Assets/BREAKFREE1.jpg')}></img> */}
                            </div>
                        </div>
                    </div>
                    <div style={{width: '100%', marginBottom: '20px', textAlign: 'left', display: 'none'}}>
                        <div style={{marginBottom: '20px', width: '100%'}}>
                            <h id="subTitle" style={{fontSize: '40px', width: '100%', marginLeft: '0%', marginTop: '0px', textAlign: 'center', lineHeight: '40px'}}>360 Immersive Lecture @ Market House, Brighton</h>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', gap: '20px'}}>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_1.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_2.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                            <div style={{width: '100%'}}>
                                <img style={{border: '2px solid black', marginBottom: '-10px'}} width={'100%'} src={require('../Assets/360_3.jpeg')}></img>
                                {/* <p>CAPTION 1</p> */}
                            </div>
                        </div>
                        <p style={{marginTop: '20px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </div>
            </div>
            </MediaQuery>
        </section>
    );

};

export default HomePage;
